import React from "react"
import { Link } from "gatsby"
import PropTypes from "prop-types"

import IconArrow from "../components/iconArrow"

const EntryCard = ({ date, photographer, location, entryId }) => {
  let photographerString = "";
  photographer.forEach((name, index) => {
    if (index < 1) {
      photographerString = name.data.photographerName
    } else {
      photographerString += (", " + name.data.photographerName)
    }
  });
  
  return (
    <Link className="entrycard" to={`/${entryId}`}>
      <div className="entrycard__content">
        <div className="entrycard__info">
          <div className="entrycard__meta">
            <span className="entrycard__date">{date}</span>
            <p className="entrycard__photographer">
              {photographerString}
            </p>
            <p className="entrycard__location">{location}</p>
          </div>
          <IconArrow className="entrycard__icon" />
        </div>
      </div>
    </Link>
  )
}

EntryCard.propTypes = {
  date: PropTypes.string,
  photographer: PropTypes.array,
  location: PropTypes.string,
  entryId: PropTypes.string,
}

EntryCard.defaultProps = {
  date: ``,
  photographer: ``,
  location: ``,
  entryId: ``,
}

export default EntryCard
