import React from "react"

const IconArrow = () => {
  return (
    <svg
      className="icon-arrow"
      fill="none"
      height="23"
      viewBox="0 0 23 23"
      width="23"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        clipRule="evenodd"
        d="m11.5 18c-3.58985 0-6.5-2.9101-6.5-6.5 0-3.58985 2.91015-6.5 6.5-6.5 3.5899 0 6.5 2.91015 6.5 6.5 0 3.5899-2.9101 6.5-6.5 6.5zm-7.5-6.5c0-4.14214 3.35786-7.5 7.5-7.5 4.1421 0 7.5 3.35786 7.5 7.5 0 4.1421-3.3579 7.5-7.5 7.5-4.14214 0-7.5-3.3579-7.5-7.5zm8.6771 3h-1.254l2.255-2.31h-6.18198v-.88h6.18198l-2.255-2.31h1.254l2.574 2.75z"
        fill="#000"
        fillRule="evenodd"
      />
    </svg>
  )
}

export default IconArrow
