import React from "react"
import { graphql } from "gatsby"

import Layout from "../components/layout"
import SEO from "../components/seo"
import EntryCard from "../components/entrycard"

const IndexPage = ({ data }) => (
  <Layout>
    <SEO title="Home" />
    <div className="scroll-container">
      <div className="cards-container">
        {data.allAirtable.edges.map(({ node }) => {
          if (
            node.data.date != null &&
            node.data.photographer != null &&
            node.data.location != null
          ) {
            return (
              <EntryCard
                key={node.id}
                date={node.data.date}
                photographer={node.data.photographer}
                location={node.data.location[0].data.locationName}
                entryId={node.id}
              />
            )
          } else {
            return <div></div>
          }
        })}
      </div>
    </div>
  </Layout>
)

export const query = graphql`
  query MyQuery {
    site {
      siteMetadata {
        title
        artist
        startYear
      }
    }
    allAirtable(
      filter: { table: { eq: "allMatresses" } }
      sort: { fields: [data___date] }
    ) {
      edges {
        node {
          id
          data {
            date(formatString: "DD.MM.YYYY")
            location {
              data {
                locationName
              }
            }
            photographer {
              data {
                photographerName
              }
            }
          }
        }
      }
    }
  }
`

export default IndexPage
